<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
    <div class="uk-margin">
      <div uk-grid class="uk-grid-small">
        <div class="uk-inline">
          <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: search"></span>
          <input class="uk-input" @keyup.enter="(e) => changeMeta(e, 'name')" type="text" placeholder="Search property name">
        </div>
      </div>
    </div>

    <div class="uk-card uk-card-default uk-margin">
      <div class="uk-overflow-auto">
        <table class="uk-table uk-table-small uk-table-divider uk-table-striped">
          <thead>
            <tr>
              <th class="uk-text-center">Company Name</th>
              <th class="uk-text-center">Type</th>
              <th class="uk-text-center">Code</th>
              <th class="uk-text-center">Name</th>
              <th class="uk-text-center">Notes</th>
              <th class="uk-text-center">Status</th>
              <th class="uk-text-center uk-width-1-5">Action</th>
            </tr>
          </thead>
          <tbody v-if="properties.docs.length>0" class="uk-table-middle uk-text-center ">
            <tr v-for="(property, i) in properties.docs" :key="i">
              <td>{{property.company.name}}</td>
              <td>{{property.type.code}} ({{property.type.description}})</td>
              <td>{{property.code}}</td>
              <td>{{property.name}}</td>
              <td>{{property.notes}}</td>
              <td>
                <div class="label uk-margin-auto" :style="`background-color: ${property.status ? '#00cc00' : '#e63c3c'}`">
                  {{property.status ? 'Active' : 'Non Active'}}
                </div>
              </td>
              <td>
                <button
                  class="uk-button uk-button-small uk-button-default"
                  type="button"
                  @click="detailSchedule(property)"
                >
                  Details
                </button>
              </td>
            </tr>
          </tbody>
          <empty-table v-else :colspan="6" />
        </table>
      </div>
      <pagination
        :total-data="properties.totalDocs"
        :change-limit="changeLimit"
        :change-page="changePage"
      />
    </div>

  </div>
</template>

<script>
import Pagination from '@/components/globals/Pagination';
import EmptyTable from '@/components/globals/tables/EmptyTable';
import { mapActions, mapGetters } from 'vuex';

export default {
    data() {
        return {
            meta: {
                limit: 100,
                page: 1,
                code: '',
                name: '',
                status: null,
                is_deleted: false,
            }
        };
    },
    components: {
        Pagination,
        EmptyTable
    },
    watch: {
        meta() {
            this.setPropertyMeta();
        },
    },
    mounted() {
        this.setPropertyMeta();
    },
    computed: {
        ...mapGetters({
            properties: 'property/properties'
        })
    },
    methods: {
        ...mapActions({
            getProperties: 'property/getProperties'
        }),
        setPropertyMeta() {
            this.getProperties(this.meta);
        },
        detailSchedule(company_office) {
            this.$router.push(`/admin/mitra/schedule/detail/${company_office.company._id}/${company_office._id}`);
            this.$store.state.company_office = company_office;
        },
        changeMeta(e, prop) {
            this.meta = {
                ...this.meta,
                [prop]: e.target.value
            };
            this.getProperties(this.meta);
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        }
    }
};
</script>
